import { fetchHomePageData } from "@/api/home";
import { fetchWishListInfo } from "@/api/wishlist";
import BannerCarousel from "@/components/carousel/Banner";
import SingleBanner from "@/components/carousel/SingleBanner";
import HomeCategoryCard from "@/components/category/HomeCategoryCard/HomeCategoryCard";
import BrandCardsHolder from "@/components/home/brands";
import HotOffersHeading from "@/components/home/hot-offers-heading";
import PetCareHolder from "@/components/home/petcare";
import Card from "@/components/product-card/large/Card";
import MetaData from "@/components/seo/MetaData";
import { getWishlistUpdatedProducts } from "@/utils/helpers/wishlist";
import _ from "lodash";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { useSelector } from "react-redux";
import dynamic from "next/dynamic";
const AddToCartModal = dynamic(
  () => import("@/components/modal/AddToCartModal"),
  {
    ssr: false,
  }
);
const CartSidebarWrapper = dynamic(
  () => import("@/components/cart/sidebarWrapper"),
  {
    ssr: false,
  }
);
const PromotionalModal = dynamic(
  () => import("@/components/modal/PromotionalModal"),
  {
    ssr: false,
  }
);

export default function Home({ data }) {
  const [isPetCareVisible] = useState(false);
  const [isPetShop] = useState(false);
  const [popularProducts, setPopularProducts] = useState(
    data?.popular_products
  );
  const [dealProducts, setDealProducts] = useState(data?.deal_products);
  const [categoriesProducts, setCategoriesProducts] = useState(
    data?.category_products
  );

  const promotionalModalStatus = useSelector(
    (state) => state.promotion.isModalOpen
  );

  const { data: session } = useSession();

  const { t: translate } = useTranslation("home");
  const MAX_CATEGORY_PRODUCTS = 4;

  useEffect(() => {
    if (session?.token) {
      fetchWishListData();
    }
  }, [session]);

  const getWishlistUpdatedCategoryList = (wishlist, categories) => {
    let updatedCategoryList = [];

    categories.forEach((category) => {
      let products = getWishlistUpdatedProducts(
        wishlist,
        category.products.slice(0, MAX_CATEGORY_PRODUCTS)
      );

      updatedCategoryList.push({
        name: category.name,
        slug: category.slug,
        products: products,
      });
    });

    return updatedCategoryList;
  };

  const fetchWishListData = async () => {
    const { data } = await fetchWishListInfo();

    if (_.isEmpty(_.get(data, ["wishlist"]))) return;

    const { wishlist } = data;

    if (!_.isEmpty(popularProducts)) {
      setPopularProducts(getWishlistUpdatedProducts(wishlist, popularProducts));
      setDealProducts(getWishlistUpdatedProducts(wishlist, dealProducts));

      setCategoriesProducts(
        getWishlistUpdatedCategoryList(wishlist, categoriesProducts)
      );
    }
  };

  if (!data) return <h1>{translate("no_data_available")}</h1>;

  return (
    <>
      <MetaData {...data.metadata} schema={data.schema} />

      <div className=" mx-auto">
        <div className="aspect-[394/122] md:aspect-[87/25]">
          <BannerCarousel sliderImages={data?.main_sliders} />
        </div>
        <div className="mt-[2.25rem] lg:mt-[5rem] mx-[1.25rem] lg:mx-[7.5rem]">
          <section>
            <div className="mb-[3rem]">
              <h1 className="text-heading1 font-medium">
                {translate("features_categories")}
              </h1>
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-[1.3rem]">
              {data?.featured_categories &&
                data?.featured_categories
                  .slice(0, 6)
                  .map((category) => (
                    <HomeCategoryCard
                      key={category.slug}
                      categorySlug={category.slug}
                      categoryName={category.name}
                      imageHolderClassName=""
                      imageClassName="w-[5.75rem] h-[5.75rem] md:w-[11.25rem] md:h-[11.25rem]"
                      imageUrl={category.logo}
                      textHolderClassName="w-[5.75rem] md:w-[11.25rem]"
                    />
                  ))}
            </div>
          </section>

          {popularProducts && (
            <section className="mt-[5rem] mb-[2.5rem]">
              <div className="mb-[2.5rem]">
                <HotOffersHeading
                  offerName="Featured Products"
                  redirectLink={`/featured-products`}
                />
              </div>

              <div className="grid grid-cols-2 lg:grid-cols-4 gap-[1.5rem]">
                {popularProducts?.slice(0, 4)?.map((product) => (
                  <Card product={product} key={product.slug} />
                ))}
              </div>
            </section>
          )}
        </div>
        {isPetCareVisible && (
          <div>
            <PetCareHolder />
          </div>
        )}
        {dealProducts.length > 0 && (
          <section className="mt-[3.5rem] lg:mt-[5rem] mx-[.75rem] lg:mx-[7.5rem]">
            <div className="mb-[2.5rem]">
              <HotOffersHeading
                offerName={translate("todays_deals")}
                redirectLink="todays-deals"
              />
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-[1.5rem]">
              {dealProducts.slice(0, 4)?.map((product) => (
                <Card product={product} key={product.slug} />
              ))}
            </div>
          </section>
        )}
        <div className="mt-[3.5rem] lg:mt-[3.5rem]">
          <SingleBanner
            className="aspect-[144/31]"
            bgImageUrl={data?.discount_banner}
            redirectUrl={data?.discount_banner_redirect_url}
          />
        </div>

        {isPetShop && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-[1.25rem] mx-[.75rem] lg:mx-[7.5rem] mt-[2.5rem]">
            <SingleBanner
              classNames="aspect-[147/80] rounded-[12px]"
              bgImageUrl={data?.pet_care}
              redirectUrl={data?.pet_care_redirect_url}
            />
            <SingleBanner
              classNames="aspect-[147/80] rounded-[12px]"
              bgImageUrl={data?.pet_shop}
              redirectUrl={data?.pet_shop_redirect_url}
            />
          </div>
        )}

        {categoriesProducts &&
          categoriesProducts.map((category) => (
            <div
              className="mt-[3.5rem] lg:mt-[5rem] mx-[.75rem] lg:mx-[7.5rem]"
              key={category.slug}
            >
              <div className="mb-[2.5rem]">
                <HotOffersHeading
                  offerName={category.name || translate("category_name")}
                  redirectLink={`category/${category?.slug}`}
                />
              </div>

              <div className="grid grid-cols-2 lg:grid-cols-4 gap-[1.5rem]">
                {category.products
                  .slice(0, MAX_CATEGORY_PRODUCTS)
                  .map((product) => (
                    <Card product={product} key={product.slug} />
                  ))}
              </div>
            </div>
          ))}

        <div className="mt-[2.5rem]">
          <BrandCardsHolder ourBrands={data.brand_sliders} />
        </div>
        <div>
          <SingleBanner
            className="aspect-[4/1]"
            bgImageUrl={data?.community}
            redirectUrl={data?.community_redirect_url}
          />
        </div>
        <CartSidebarWrapper />
        <AddToCartModal />
      </div>
      {promotionalModalStatus && (
        <PromotionalModal isActive={promotionalModalStatus} />
      )}
    </>
  );
}

export async function getStaticProps({ locale }) {
  const resData = await fetchHomePageData();

  if (resData) {
    return {
      props: {
        ...(await serverSideTranslations(locale, ["common", "home"])),
        data: resData.data,
      },
    };
  }

  return {
    props: { data: null },
  };
}
