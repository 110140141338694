import en from "@/localization/en.json";
import Image from "next/image";
import Link from "next/link";
import { PropTypes } from "prop-types";

function HotOffersHeading({ offerName, redirectLink }) {
  return (
    <div className="flex justify-between items-center">
      <h1 className="text-heading1 font-medium">{offerName}</h1>
      <Link href={redirectLink}>
        <div className="flex items-center">
          <h5 className="text-[#E81E61] mr-[.5rem] cursor-pointer">
            {en["home.view_all"]}
          </h5>
          <div>
            <Image
              src="/assets/icons/next-label-red.png"
              width={9}
              height={15}
              alt="view all"
            />
          </div>
        </div>
      </Link>
    </div>
  );
}

HotOffersHeading.propTypes = {
  offerName: PropTypes.string,
  redirectLink: PropTypes.string,
};

export default HotOffersHeading;
